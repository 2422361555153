<template>
  <feature-selector-p :features="features" @input="handleInput" />
</template>

<script>
import { Constants } from "Services/Constants"
const FeatureSelectorP = () => import("Components/VehicleSearch/FeatureSelectorP")
export default {
  data() {
    let features = Constants.features;
    features.forEach((f) => {
      const indexOfFeature = this.$store.state.searchOptions.features.findIndex(
        (x) => x.name == f.value
      );
      f.selected = indexOfFeature > -1;
      f.rank = f.selected
        ? this.$store.state.searchOptions.features[indexOfFeature].rank
        : 0;
    });
    return {
      features
    };
  },
  props: {
    value: Array,
  },
  methods: {
    handleInput($event){
      this.$emit('input', $event)
    }
  },
  components: { FeatureSelectorP }
};
</script>
